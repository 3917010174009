<template>
  <div>
    <div class="content not-select">
      <div class="center">
        <div class="left">
          <div class="officialTalk">
            <div v-for="item in officialTalk" :key="item.id" class="box">
              <p class="title"> {{ item.title }} </p>
              <p class="content">{{ item.content }}</p>
              <p class="goTalk"><span>{{ item.view }}用户正在围观</span> <span>进入讨论<i class="el-icon-d-arrow-right" /></span> </p>
            </div>
          </div>
          <div class="bank-forum">
            <div class="banks">
              <div class="title">
                <span><i class="el-icon-data-line" />官方题库</span>
                <span>更多<i class="el-icon-arrow-right" /></span>
              </div>
              <div id="listBox" class="list">
                <div id="rowBox" class="row-box">
                  <div v-for="item in banks" :key="item.id" class="item">
                    <div class="img"><i :class="item.icon" /></div>
                    <p class="name">{{ item.name }}</p>
                    <p class="view">热门指数: {{ item.view }}</p>
                    <div class="btn" @click="toBank(item)">查看详情</div>
                  </div>
                </div>
                <div id="leftMarks" class="left-marks" />
                <div id="rightMarks" class="right-marks" />
                <div class="left-btn" @click="showHidden('left')"><i class="el-icon-arrow-left" /></div>
                <div class="right-btn" @click="showHidden('right')">
                  <i class="el-icon-arrow-right" />
                </div>

              </div>

            </div>
            <div class="forum">
              <div v-for="item in forum" :key="item.id" class="item">
                <div class="left" @click="toDetail(item.id)">
                  <img :src="item.imgURL" alt="">
                </div>
                <div class="right">
                  <div class="title" @click="toDetail(item.id)">{{ item.title }}</div>
                  <div class="contents" @click="toDetail(item.id)">{{ item.text }}</div>
                  <div class="tags"> {{ item.tags }} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <el-input
            v-model="input2"
            class="search initBox"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
          />
          <div slot="reference" class="addTalks initBox">
            <p @click="goToAddTalks('Markdown')"><i class="el-icon-edit-outline" /><span>MD 格式</span></p>
            <p @click="goToAddTalks('Normal')"><i class="el-icon-edit" /><span>普通格式</span></p>
          </div>

        </div>

        <!--
        <button @click="goToIndex">跳转到查重修改工具</button>
        <br />
        <button @click="goToEnglish">查看所有工具集</button>
        <br />
        <button @click="goToForum">查看论坛</button>
        <br />
        <button @click="useClaws">使用CLAWS工具</button>
        <br />
        <button @click="goToEnglish">使用USAS工具</button> -->
      </div>
    </div>
    <footer-page class="footer" />
  </div>
</template>

<script>
import FooterPage from '../../components/Footer/index.vue'
import { animate } from 'popmotion'
export default {
  components: { FooterPage },
  data: () => ({
    input2: '',
    officialTalk: [
      {
        id: 1,
        title: '📚读书日到了，有哪些值得程序员反复阅读的好书？',
        content: `📖 阅读的目的应该是变得更有智慧，而不只是变得更有知识。
                  尤其是对于行业发展迅猛，技术快速迭代的程序员群体而言，读一本真正的好书，远胜于读一堆的书。
                  那么，在程序员的职业成长过程中，有哪些值得被反复阅读的好书呢？
                  📚 世界读书日即将到来，如果你的身边恰好有这样的好书，那么不妨一起来参与本次读书日活动，分享你心目中值得被反复阅读的真正 「 好书 」。
                  我们将在活动结束后，根据用户们推荐的好书整理成主题书单，为更多的力扣用户提供价值。`,
        tags: ['读书', '程序员'],
        view: 2000
      },
      {
        id: 2,
        title: '📚读书日到了，有哪些值得程序员反复阅读的好书？',
        content: `📖 阅读的目的应该是变得更有智慧，而不只是变得更有知识。
                  尤其是对于行业发展迅猛，技术快速迭代的程序员群体而言，读一本真正的好书，远胜于读一堆的书。
                  那么，在程序员的职业成长过程中，有哪些值得被反复阅读的好书呢？
                  📚 世界读书日即将到来，如果你的身边恰好有这样的好书，那么不妨一起来参与本次读书日活动，分享你心目中值得被反复阅读的真正 「 好书 」。
                  我们将在活动结束后，根据用户们推荐的好书整理成主题书单，为更多的力扣用户提供价值。`,
        tags: ['读书', '程序员'],
        view: 2000
      }
    ],
    banks: [
      {
        id: 1,
        name: '财院-大学英语',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: 'Bank'
      },
      {
        id: 2,
        name: '软件设计与开发',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: ''
      },
      {
        id: 3,
        name: 'JavaScript编程',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: ''
      },
      {
        id: 4,
        name: '前端从入门到放弃',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: ''
      },
      {
        id: 5,
        name: 'HTML',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: ''
      },
      {
        id: 6,
        name: '特斯拉',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: ''
      },
      {
        id: 7,
        name: '特斯拉',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: ''
      },
      {
        id: 8,
        name: '特斯拉',
        view: 5704,
        icon: 'el-icon-cpu',
        URLName: ''
      }
    ],
    forum: [
      {
        id: 1,
        title: '题目交流，JavaScript 怎么让1 + 1 = 1',
        content: '如图所示，图二为运行结果 请问重载符重载函数+哪里出错了 正确运行答案还有string1的字符串内存，但是运行结果并没有显示',
        imgURL: 'https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/order-images/1702cd9f837848948dc8814a4b8fa0f0.jpg',
        tags: '前端'
      },
      {
        id: 2,
        title: '题目交流，JavaScript 怎么让1 + 1 = 1',
        content: '如图所示，图二为运行结果 请问重载符重载函数+哪里出错了 正确运行答案还有string1的字符串内存，但是运行结果并没有显示',
        imgURL: 'https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/order-images/1702cd9f837848948dc8814a4b8fa0f0.jpg',
        tags: '前端'
      },
      {
        id: 3,
        title: '题目交流，JavaScript 怎么让1 + 1 = 1',
        content: '如图所示，图二为运行结果 请问重载符重载函数+哪里出错了 正确运行答案还有string1的字符串内存，但是运行结果并没有显示',
        imgURL: 'https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/order-images/1702cd9f837848948dc8814a4b8fa0f0.jpg',
        tags: '前端'
      },
      {
        id: 4,
        title: '题目交流，JavaScript 怎么让1 + 1 = 1',
        content: '如图所示，图二为运行结果 请问重载符重载函数+哪里出错了 正确运行答案还有string1的字符串内存，但是运行结果并没有显示',
        imgURL: 'https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/order-images/1702cd9f837848948dc8814a4b8fa0f0.jpg',
        tags: '前端'
      },
      {
        id: 5,
        title: '题目交流，JavaScript 怎么让1 + 1 = 1',
        content: '如图所示，图二为运行结果 请问重载符重载函数+哪里出错了 正确运行答案还有string1的字符串内存，但是运行结果并没有显示',
        imgURL: 'https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/order-images/1702cd9f837848948dc8814a4b8fa0f0.jpg',
        tags: '前端'
      }
    ]
  }),
  created() {
    this.$store.dispatch('user/doTest')
  },
  mounted() {
    this.initBankCard()
    this.getAllTalks()
  },
  methods: {
    /**
     * initBankCard 初始化设置官方题库行的两个切换图标，以及两边白色渐变遮罩的显示与隐藏
     * @param { Boolen } isShow 滚动条的左侧位置是否为 0 ，如果是,传入 false , 反之为 true
     * @param { Number } to 滚动条下一步需要到达的位置数值
     */
    initBankCard(isShow, to) {
      const TotalWidth = (168 * (this.banks.length)) + (16 * this.banks.length) + 40 - 734
      const leftMarks = document.getElementById('leftMarks')
      const rightMarks = document.getElementById('rightMarks')
      const leftBtn = document.querySelector('.left-btn')
      const rightBtn = document.querySelector('.right-btn')
      if (isShow) {
        leftMarks.style.display = 'block'
        leftBtn.style.display = 'block'
        if (to >= TotalWidth) {
          rightMarks.style.display = 'none'
          rightBtn.style.display = 'none'
        } else {
          rightMarks.style.display = 'block'
          rightBtn.style.display = 'block'
        }
      } else {
        leftMarks.style.display = 'none'
        leftBtn.style.display = 'none'
      }
    },
    showHidden(direction) {
      const listBox = document.getElementById('listBox')
      const now = document.getElementById('listBox').scrollLeft
      const to = direction === 'right' ? now + 332 : now - 332
      if (to > 0) {
        this.initBankCard(true, to)
      } else {
        this.initBankCard(false, to)
      }
      if (direction === 'right') {
        animate({
          from: now,
          to,
          onUpdate: latest => listBox.scrollTo(latest, 0)
        })
      } else {
        animate({
          from: now,
          to,
          onUpdate: latest => listBox.scrollTo(latest, 0)
        })
      }
    },

    toDetail(id) {
      this.$router.push({ name: 'ForumDetail', params: { id: id }})
    },

    async getAllTalks() {
      const imgURL = 'https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/order-images/1702cd9f837848948dc8814a4b8fa0f0.jpg'
      const { results = [] } = await this.$store.dispatch('forum/getAllTalks', { status: 1 })
      for (const item of results) {
        item.imgURL = imgURL
      }
      this.forum = results
    },

    toBank(item) {
      if (item.URLName) {
        this.$router.push({ name: item.URLName })
      }
    },

    goToIndex() {
      this.$router.push('/Paper')
    },
    goToEnglish() {
      this.$router.push('/AllTools')
    },
    goToAddTalks(where) {
      const params = where === 'Markdown' ? 'Markdown' : 'Normal'
      this.$router.push({ name: 'Forum', query: { type: params }})
    }
    // useClaws() {
    //   this.$router.push("/AllTools");
    // },
    // useUsas() {
    //   this.$router.push("/AllTools");
    // },
  }
}
</script>

<style lang="less" scoped>
@hightBlue: rgb(0,122,255);
.initBox {
        border-radius: 8px;
        box-shadow:  rgba(0, 0, 0, 0.04) 0px 1px 3px, rgba(0, 0, 0, 0.08) 0px 2px 8px;
        background-color: #fff;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;

  .center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1000px;
    min-height: calc(100vh - 60px);
    padding-top: 20px;
    padding-bottom: 20px;

    .left {
      width: 734px;
      height: auto;

      .officialTalk {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 152px;
        .box {
          position: relative;
          &::before {
            position: absolute;
            right: 16px;
            top: 16px;
            content: '热议中';
            width: 36px;
            height: 20px;
            background-color: rgba(255, 161, 22, 0.15);
            border-radius: 12px;
            padding: 2px 8px;
            font-size: 12px;
            line-height: 20px;
            color: rgba(255, 161, 22, 1);
          }
          width: 362px;
          height: 100%;
          border-radius: 8px;
          padding: 16px;
          overflow: hidden;
          background-color: #fff;
          box-shadow: 0 2px 8px rgba(var(--dsw-black-rgb), 0.08),0 1px 2px rgba(var(--dsw-black-rgb), 0.1);
          .title {
            width: 278px;
            height: 20px;
            font-size: 16px;
            font-weight: 500;
            color: rgba(var(--dsw-label-primary-rgb), 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            line-height: 20px;
          }
          .content {
            -webkit-line-clamp: 2;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin: 16px 0px;
            height: 40px;
            font-size: 14px;
            background-color: #fff;
          }
          .goTalk {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            span:nth-child(2) {
              color: @hightBlue;
            }
          }
        }
      }

      .bank-forum {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 20px 0;
        border-radius: 8px;
        background-color: #fff;
        .banks {
            position: relative;
          width: 100%;
          font-weight: 500;
          .title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 22px;
            font-size: 16px;
            padding: 0 20px;
            span:nth-child(1) i {
              display: inline-block;
              margin-right: 5px;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              text-align: center;
              line-height: 22px;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08),0 1px 2px rgba(0, 0, 0, 0.1);
            }
            span:nth-child(2) {
              font-size: 12px;
              cursor: pointer;
            }
          }
          .list {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            overflow-x: hidden;
            height: 198px;
            width: 100%;
            .row-box {
              display: flex;
              flex-wrap: nowrap;
              padding: 0 20px;
              .item {
                display: flex;
                flex-direction: column;
                margin-right: 16px;
                width: 168px;
                height: 166px;
                border-radius: 13px;
                padding: 16px;
                background-color: #fff;
                overflow: auto hidden;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08),0 1px 2px rgba(0, 0, 0, 0.1);
                .img {
                  width: 42px;
                  height: 42px;
                  text-align: center;
                  line-height: 42px;
                  i {
                    font-size: 30px;
                  }
                }
                .name {
                  height: 22;
                  line-height: 22px;
                  font-size: 16px;
                }
                .view {
                  height: 18px;
                  line-height: 18px;
                  font-size: 12px;
                  color: rgba(140, 140, 140, 1);
                }
                .btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  line-height: 18px;
                  width: 136px;
                  padding: 4px 11px;
                  margin-top: 16px;
                  color: rgba(0,122,255, 1);
                  background: rgba(0,122,255,0.08);
                  border-radius: 7px;
                  transition: all 0.4s ease 0s;
                  -webkit-box-align: center;
                  user-select: none;
                  height: 28px;
                  cursor: pointer;
                }
              }
            }
            .left-marks::before {
              content: "";
              position: absolute;
              top: 0px;
              bottom: 0px;
              width: 45px;
              transition: opacity 0.3s ease 0s;
              background: linear-gradient(to right, rgba(255, 255, 255, 1) 61.98%, rgba(255, 255, 255, 0) 100%);
              opacity: 1;
              right: 0;
              transform: translateX(100%);
            }
            .right-marks::before {
              content: "";
              position: absolute;
              top: 0px;
              bottom: 0px;
              width: 45px;
              transition: opacity 0.3s ease 0s;
              background: linear-gradient(to left, rgba(255, 255, 255, 1) 61.98%, rgba(255, 255, 255, 0) 100%);
              opacity: 1;
              left: 0;
              transform: translateX(-100%);
            }
            .left-marks {
              position: absolute;
              width: 0;
              height: 180px;
              left: 0;
              z-index: 10;
            }
            .right-marks {
              position: absolute;
              height: 180px;
              width: 0;
              right: 0;
            }
            .right-btn {
              position: absolute;
              right: 10px;
              width: 32px;
              height: 32px;
              text-align: center;
              line-height: 32px;
              border-radius: 50%;
              background-color: #fff;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08),0 1px 2px rgba(0, 0, 0, 0.1);
              transition: all .3s;
              z-index: 11;
              opacity: 0;
              transition: all .3s;
            }
            .left-btn {
              position: absolute;
              left: 10px;
              width: 32px;
              height: 32px;
              text-align: center;
              line-height: 32px;
              background-color: #fff;
              border-radius: 50%;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08),0 1px 2px rgba(0, 0, 0, 0.1);
              transition: all .3s;
              z-index: 11;
              opacity: 0;
              transition: all .3s;
            }
            &:hover .left-btn,
            &:hover .right-btn {
              opacity: 1;
            }
            .right-btn:hover,
            .left-btn:hover {
              background-color: #007aff;
            }
          }
        }

        .forum {
          display: flex;
          flex-direction: column;

          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 152px;
            padding: 0 20px;
            border-top: 1px solid #FAFAFA;

            .left {
              width: 200px;
              height: 120px;
              border-radius: 10px;
              overflow: hidden;
              margin-right: 20px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: calc(100% - 240px);
              height: 120px;
              .title {
                font-size: 16px;
              }
              .contents {
                display: -webkit-box;
                height: 40px;
                font-size: 14px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .tags {
                width: 58px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                background-color: #e0f4e7;
                border-radius: 12px;
                color: rgb(45,181,93);
              }
            }
          }

        }
      }

    }
    .right {
      display: flex;
      flex-direction: column;
      width: 256px;
      .search {
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
      }
      .search:hover /deep/ .el-input__inner {
        border: 1px solid @hightBlue;
      }
      .search /deep/ .el-input__inner {
        border: 1px solid transparent;
        border-radius: 8px;
      }
      .addTalks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        width: 100%;
        p {
          color: rgb(45,181,93);
          cursor: pointer;
          i {
            display: inline-block;
            margin-right: 5px;
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            border-radius: 50%;
            background: linear-gradient(rgb(45, 181, 140) 0%, rgb(45, 181, 93) 100%);
            box-shadow: rgba(0, 255, 90, 0.2) 0px 4px 12px;
            font-size: 20px;
            color: #fff;
          }
          span {
            font-size: 14px;
          }

        }
        p:nth-child(2) {
          color: rgba(175,82,222, 1);
          i {
            background: linear-gradient(rgb(208, 116, 255) 0%, rgb(175, 82, 222) 100%);
            box-shadow: rgba(204, 103, 255, 0.4) 0px 4px 12px;
          }
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
  }
}

</style>

<style scoped>
@import "../../assets/CSS/common.css";

/* .big-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 400px;
  background: url("../../assets/images/bg01.jpg") no-repeat -1200px -258px;
  border-radius: 30px;
}
.big-box .info {
  position: absolute;
  width: 350px;
  color: aliceblue;
  left: 100px;
}
.big-box .info p {
  font-size: 12px;
}
.window {
  position: absolute;
  right: 150px;
  top: 50px;
  width: 300px;
  height: 400px;
  border: 10px solid #fff;
  border-radius: 250px 250px 20px 20px;
  background-image: url("../../assets/images/window-bg.jpg");
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: -90px 0;
}

button {
  margin-top: 50px;
  width: 100%;
  height: 50px;
  background-color: skyblue;
  border-radius: 25px;
  transition: all 1s;
}

button:hover {
  background-color: tomato;
} */
</style>
